import React from "react";
import Page from "@jumbo/shared/Page";
import UserManagement from "app/pages/management/user/UserManagement";
import BannerManagement from "app/pages/management/banner/BannerManagement";
import NotificationManagement from "app/pages/management/notification/NotificationManagement";

const managementRoutes = [
  {
    path: [
      "/management/user/:category",
      "/management/user/:category/message/:messageID",
    ],
    element: <Page component={UserManagement} />,
  },
  {
    path: [
      "/management/banner/:category",
      "/management/banner/:category/message/:messageID",
    ],
    element: <Page component={BannerManagement} />,
  },
  {
    path: [
      "/management/notification/:category",
      "/management/notification/:category/message/:messageID",
    ],
    element: <Page component={NotificationManagement} />,
  },
];
export default managementRoutes;
