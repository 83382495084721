import JumboButton from "@jumbo/components/JumboButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import * as yup from "yup";
import IconBreadcrumbs from "../components/mui/Breadcrumbs/IconBreadcrumbs";
import { CurrencyExchangeOutlined } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const initialValues = {
  source_account: "",
  destination_account: "",
  destination_bank_code: "",
  amount_of_money: "",
};

const validationSchema = yup.object({
  source_account: yup.string().required("จำเป็นต้องกรอก"),
  destination_account: yup.string().required("จำเป็นต้องกรอก"),
  destination_bank_code: yup.string().required("จำเป็นต้องกรอก"),
  amount_of_money: yup.string().required("จำเป็นต้องกรอก"),
});

const Disbursement = () => {
  const theme = useTheme();
  const Swal = useSwalWrapper();
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-left",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  function onSaveData(data) {
    console.log(data);
    Toast.fire({
      icon: "success",
      title: "เพิ่มข้อมูลแล้ว",
      background: theme.palette.background.paper,
    });
  }
  return (
    <Fragment>
      <IconBreadcrumbs
        page={
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <CurrencyExchangeOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
            Disbursement
          </Typography>
        }
      />
      <PageHeader title={"Disbursement"} />
      <Card sx={{ width: "100%", height: "100%" }}>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            onSaveData(data);
            resetForm();
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ display: "flex", flexWrap: "wrap", height: "100%" }}
              noValidate
              autoComplete="off"
            >
              <MNCardContent>
                <Grid container item xs={6} rowGap={3}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <MNTypography>
                      บัญชีต้นทาง<span>*</span> :
                    </MNTypography>
                    <MNTextField
                      size="small"
                      name="source_account"
                    ></MNTextField>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <MNTypography>
                      บัญชีปลายทาง<span>*</span> :
                    </MNTypography>
                    <MNTextField
                      size="small"
                      name="destination_account"
                    ></MNTextField>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <MNTypography>
                      รหัสธนาคารปลายทาง<span>*</span> :
                    </MNTypography>
                    <MNTextField
                      size="small"
                      name="destination_bank_code"
                    ></MNTextField>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <MNTypography>
                      จำนวนเงิน<span>*</span> :
                    </MNTypography>
                    <MNTextField
                      size="small"
                      name="amount_of_money"
                    ></MNTextField>
                  </Grid>
                </Grid>
              </MNCardContent>
              <MNCardActions>
                <JumboButton
                  type="submit"
                  variant={"contained"}
                  disabled={isSubmitting}
                >
                  Save
                </JumboButton>
              </MNCardActions>
            </Form>
          )}
        </Formik>
      </Card>
    </Fragment>
  );
};

const MNTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  "& span": {
    color: "red",
  },
}));

const MNTextField = styled(JumboTextField)(({ theme }) => ({
  width: 301,
  height: 40,
}));

const MNCardContent = styled(CardContent)(({ theme }) => ({}));

const MNCardActions = styled(CardActions)(({ theme }) => ({
  width: "100%",
  padding: "24px",
  justifyContent: "flex-end",
  alignItems: "flex-end",
}));

export default Disbursement;
