import React from "react";
import MUIDataTable from "mui-datatables";
import { styled, Box } from "@mui/material";

const TableData = ({ ...restProps }) => {
  return (
    <div>
      <TableStyle>
        <MUIDataTable {...restProps} />
      </TableStyle>
    </div>
  );
};

export default TableData;

const TableStyle = styled(Box)({
  "& >div": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
