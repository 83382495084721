import { BASE_PATH } from "app/utils/constants/paths";
import jwtAuthAxios from "./auth/jwtAuth";

const fileServices = {};

fileServices.getfile = async (id) => {
  // const { data } = await jwtAuthAxios.get("/file/".concat(id));
  return BASE_PATH.concat("/file/" + id);
};

fileServices.postfile = async (file) => {
  var formData = new FormData();
  formData.append("file", file);
  const { data } = await jwtAuthAxios.post("/file/upload", formData);
  return data;
};

export default fileServices;
