import React from "react";
import MUIDataTable from "mui-datatables";
import { styled, Box } from "@mui/material";


const TableReport = (props) => {
  const { data, columns, options } = props
  
  return (
    <div>
      <TableStyle>
        <MUIDataTable
          title={"ReportDisbursement"}
          data={data}
          columns={columns}
          options={options}
        />
      </TableStyle>
    </div>
  );
};

export default TableReport;

const TableStyle = styled(Box)({
  "& >div": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
