import jwtAuthAxios from "./auth/jwtAuth"; // Replace with the actual path to your jwtAuthAxios file


export const getDisbursementReData = async (page, limit) => {
  try {
    const response = await jwtAuthAxios.get("/report/disbursement", {
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
