import Page from "@jumbo/shared/Page";
import BannerSlide from "app/pages/app-config/banner-slide/BannerSlide";

const appConfigRoutes = [
  {
    path: "/app-config/banner-slide",
    element: <Page component={BannerSlide} />,
  },
];

export default appConfigRoutes;
