import { TextField, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useField, useFormikContext } from "formik";
import React from "react";

const MNDateTimePicker = ({ minName, minDateTime, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [fieldst] = useField(minName);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        {...field}
        {...props}
        ampm={false}
        disabled={field.name !== minName && !fieldst.value}
        minDateTime={
          field.name !== minName ? new Date(fieldst.value) : minDateTime
        }
        inputFormat="dd/MM/yyyy HH:mm:ss"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, new Date(val).toISOString());
        }}
        renderInput={(params) => (
          <MNTextField
            {...params}
            size="small"
            helperText={errorText}
            error={!!errorText}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default MNDateTimePicker;

const MNTextField = styled(TextField)(({ theme }) => ({
  width: 301,
  height: 40,
}));
