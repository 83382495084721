import React from "react";
import ContactsAppProvider from "app/pages/apps/contacts/ContactsAppProvider";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import ContactsAppSidebar from "app/pages/apps/contacts/components/ContactsAppSidebar";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ContactsList from "app/pages/apps/contacts/components/ContactsList";

const UserManagement = () => {
  return (
    <ContactsAppProvider>
      <JumboContentLayout
        sidebar={<ContactsAppSidebar />}
        header={
          <PageHeader
            title={"Contacts"}
            subheader={"A ready to integrate ui to build a contacts module."}
          />
        }
      >
        <ContactsList />
      </JumboContentLayout>
    </ContactsAppProvider>
  );
};

export default UserManagement;
