import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SalesOverview from "../../../shared/metrics/SalesOverview";
import Documents1 from "../../../shared/widgets/Documents1";
import Ideas1 from "../../../shared/widgets/Ideas1";
import ScheduleCard from "../../../shared/widgets/ScheduleCard";
import LastMonthSales from "../../../shared/metrics/LastMonthSales";
import OnlineSignupsFilled from "../../../shared/metrics/OnlineSignupsFilled";
import NewVisitorsThisMonth from "../../../shared/metrics/NewVisitorsThisMonth";
import TotalRevenueThisYear from "../../../shared/metrics/TotalRevenueThisYear";
import SalesReport1 from "../../../shared/metrics/SalesReport1";
import AppUsers from "../../../shared/metrics/AppUsers";

const MiscDashboard = () => {
  const [reloadPage, setReloadPage] = useState(true);

  useEffect(() => {
    setTimeReloadPage();
  }, []);

  function setTimeReloadPage() {
    setTimeout(function () {
      setReloadPage(false);
    }, 100);
  }
  return (
    <Fragment>
      {reloadPage ? (
        <Grid></Grid>
      ) : (
        <Grid container spacing={3.75}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={3.75}>
              <Grid item xs={12} lg={9}>
                <SalesOverview />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Grid container spacing={3.75}>
                  <Grid item xs={12} sm={6} lg={12}>
                    <Documents1 />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={12}>
                    <Ideas1 />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <SalesReport1 />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppUsers />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ScheduleCard />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <OnlineSignupsFilled />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <NewVisitorsThisMonth />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LastMonthSales />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TotalRevenueThisYear />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default MiscDashboard;
