import JumboButton from "@jumbo/components/JumboButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Form, Formik, useField, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import MNDateTimePicker from "app/pages/components/common/MNDateTimePicker";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import fileServices from "app/services/file-service";
import bannerSlideServices from "app/services/banner-slide-service";
import MNUploadFile from "app/pages/components/common/MNUploadFile";

const initialValues = {
  id: 0,
  title: "",
  image: "",
  desc: "",
  start_datetime: "",
  end_datetime: "",
  order: "",
  enable: true,
};

const validationSchema = yup.object({
  title: yup.string().required("จำเป็นต้องกรอก"),
  image: yup.string().required("จำเป็นต้องกรอก"),
  desc: yup.string().required("จำเป็นต้องกรอก"),
  start_datetime: yup.string().required("จำเป็นต้องกรอก"),
  end_datetime: yup.string().required("จำเป็นต้องกรอก"),
  order: yup.number().required("จำเป็นต้องกรอก"),
});

const DataForm = ({ data, type, open, setOpen, setResponse }) => {
  const theme = useTheme();
  const Swal = useSwalWrapper();
  const [imageBanner, setImageBanner] = useState("");
  const [showImageBanner, setShowImageBanner] = useState("");

  useEffect(() => {
    if (type === "edit") {
      setImageBanner(data?.image);
      setShowImageBanner(data?.image);
    }
  }, [data]);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-left",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  function getImageById(id) {
    fileServices
      .getfile(id)
      .then((data) => {
        setShowImageBanner(data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  function uploadImage({ target }) {
    if (target.files[0]) {
      fileServices
        .postfile(target.files[0])
        .then((data) => {
          getImageById(data.data.files[0].Id);
          setImageBanner(data.data.files[0].Id);
          Toast.fire({
            icon: "success",
            title: "เพิ่มข้อมูลแล้ว",
            background: theme.palette.background.paper,
          });
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      setImageBanner("");
    }
  }

  function onSaveData(data) {
    if (type === "edit") {
      bannerSlideServices
        .putBannerSlide(data)
        .then((resp) => {
          setResponse(resp.data);
          Toast.fire({
            icon: "success",
            title: "เพิ่มข้อมูลแล้ว",
            background: theme.palette.background.paper,
          });
          setOpen(false);
          setImageBanner("");
          setShowImageBanner("");
        })
        .catch(function (error) {
          let msg = "404 Not Found";
          Toast.fire({
            icon: "error",
            title: msg,
            background: theme.palette.background.paper,
          });
        });
    } else {
      bannerSlideServices
        .postBannerSlide(data)
        .then((resp) => {
          setResponse(resp.data);
          Toast.fire({
            icon: "success",
            title: "เพิ่มข้อมูลแล้ว",
            background: theme.palette.background.paper,
          });
          setOpen(false);
          setImageBanner("");
          setShowImageBanner("");
        })
        .catch(function (error) {
          let msg = "404 Not Found";
          Toast.fire({
            icon: "error",
            title: msg,
            background: theme.palette.background.paper,
          });
        });
    }
  }

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open}>
      <Grid container justifyContent={"space-between"}>
        <DialogTitle sx={{ fontSize: "25px" }}>Add Banner</DialogTitle>
        <Grid>
          <IconButton
            size="large"
            onClick={() => {
              setOpen(false);
              setImageBanner("");
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Formik
          validateOnChange={true}
          initialValues={data?.id ? data : initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            onSaveData(data);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ display: "flex", flexWrap: "wrap", height: "100%" }}
              noValidate
              autoComplete="off"
            >
              <Grid container item xs={12} rowGap={3}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"start"}
                >
                  <MNTypography>
                    รูปภาพ<span>*</span> :
                  </MNTypography>
                  <Grid container item xs={6.8} rowGap={3}>
                    {showImageBanner && (
                      <img src={showImageBanner} alt="preview" width={"30%"} />
                    )}
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={uploadImage}
                    />
                    <MNUploadFile name="image" value={imageBanner} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <MNTypography>
                    หัวข้อ<span>*</span> :
                  </MNTypography>
                  <MNTextField size="small" name="title"></MNTextField>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <MNTypography>
                    รายละเอียด<span>*</span> :
                  </MNTypography>
                  <MNTextField size="small" name="desc"></MNTextField>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <MNTypography>
                    วันเริ่ม<span>*</span> :
                  </MNTypography>
                  <MNDateTimePicker
                    name="start_datetime"
                    minDateTime={new Date()}
                    minName="start_datetime"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <MNTypography>
                    วันสิ้นสุด<span>*</span> :
                  </MNTypography>
                  <MNDateTimePicker
                    name="end_datetime"
                    minName="start_datetime"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <MNTypography>
                    การจัดเรียง<span>*</span> :
                  </MNTypography>
                  <MNTextField
                    size="small"
                    name="order"
                    type="number"
                  ></MNTextField>
                </Grid>
              </Grid>
              <Grid
                mt={5}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <JumboButton
                  type="submit"
                  variant={"contained"}
                  disabled={isSubmitting}
                >
                  บันทึก
                </JumboButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default DataForm;

const MNTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  "& span": {
    color: "red",
  },
}));

const MNTextField = styled(JumboTextField)(({ theme }) => ({
  width: 301,
  height: 40,
}));
