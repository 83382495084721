// A style sheet
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1200,
  },
  popup: {
    width: "24em",
    overflow: "hidden",
    borderRadius: 8,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "400",
    color: theme.typography.h1.color,
  },
  closeButton: {
    color: theme.palette.text.primary,

    "&:focus": {
      boxShadow: "none",
    },
    "&:hover": {
      color: theme.typography.h1.color,
    },
  },
  image: {
    margin: theme.spacing(0, "auto", 2),
  },
  htmlContainer: {
    fontSize: "1rem",
    lineHeight: 1.5,
    margin: theme.spacing(1, 3, 0.5),

    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  confirmButton: {
    width: "145px",
    border: 0,
    margin: ".3125em",
    cursor: "pointer",
    color: theme.palette.common.white,
    boxSizing: "border-box",
    fontSize: "0.875rem",
    lineHeight: 1.75,
    padding: theme.spacing(0.75, 2),
    borderRadius: 4,
    fontFamily: "inherit",
    background:
      "var(--Linear, linear-gradient(300deg, #FF9200 6.64%, #FD840D 9.04%, #F7553A 17.84%, #F2305D 26.25%, #EF1577 34.25%, #ED0586 41.06%, #ED008C 46.66%))",
    "&:hover": {
      background:
        "var(--Linear, linear-gradient(300deg, #FF9200 6.64%, #FD840D 9.04%, #F7553A 17.84%, #F2305D 26.25%, #EF1577 34.25%, #ED0586 41.06%, #ED008C 46.66%))",
    },
  },
  cancelButton: {
    width: "145px",
    border: 0,
    margin: ".3125em",
    cursor: "pointer",
    color: theme.palette.common.white,
    boxSizing: "border-box",
    fontSize: "0.875rem",
    lineHeight: 1.75,
    padding: theme.spacing(0.75, 2),
    borderRadius: 4,
    fontFamily: "inherit",
    backgroundColor: theme.palette.grey[600],

    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  footer: {
    marginTop: "1.5rem",
    fontSize: ".875rem",
    borderColor: theme.palette.divider,
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
