import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import TableData from "app/pages/components/table/TableData";
import bannerSlideServices from "app/services/banner-slide-service";
import formatDate from "app/utils/FormatDate";
import React from "react";

const Table = ({ data, setOpen, setEditData, setResponse, setTypeActions }) => {
  const theme = useTheme();
  const Swal = useSwalWrapper();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-left",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const DeleteAlerts = (id) => {
    Swal.fire({
      title: "ยืนยันลบข้อมูล",
      text: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        bannerSlideServices
          .deleteBannerSlide(id)
          .then((resp) => {
            setResponse(resp.data);
            Toast.fire({
              icon: "success",
              title: "ลบข้อมูลแล้ว",
              background: theme.palette.background.paper,
            });
          })
          .catch(function (error) {
            let msg;
            const resp = error.response.data;
            switch (resp.respCode) {
              case 4006:
                msg = resp.respMsg;
                break;
              default:
                msg = "404 Not Found";
                break;
            }
            Toast.fire({
              icon: "error",
              title: msg,
              background: theme.palette.background.paper,
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "id",
      label: "ลำดับ",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "image",
      label: "รูปภาพ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <img src={value} alt={"banner"} width={"100%"} />;
        },
      },
    },
    {
      name: "title",
      label: "หัวข้อ",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "desc",
      label: "รายละเอียด",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "start_datetime",
      label: "วันเริ่ม",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return formatDate(value);
        },
      },
    },
    {
      name: "end_datetime",
      label: "วันสิ้นสุด",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return formatDate(value);
        },
      },
    },
    {
      name: "order",
      label: "การจัดเรียง",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "button_actions1",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button
              onClick={() => {
                setOpen(true);
                setTypeActions("edit");
                setEditData(tableMeta.tableData[tableMeta.rowIndex]);
              }}
            >
              <Edit sx={{ marginRight: 1 }} fontSize="small" /> Edit
            </Button>
          );
        },
      },
    },
    {
      name: "button_actions2",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button onClick={() => DeleteAlerts(tableMeta.rowData[0])}>
              <DeleteOutline sx={{ marginRight: 1 }} fontSize="small" /> Delete
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    rowsPerPageOptions: [1000, 10], // 1000 is the first option
    rowsPerPage: 1000,
    search: false,
    // searchText: searchText,
    // onSearchChange: (search) => setSearchText(search || ""),
  };

  return <TableData data={data} columns={columns} options={options} />;
};

export default Table;
