import { TextField, styled } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useEffect } from "react";

const MNUploadFile = ({ value, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  useEffect(() => {
    setFieldValue(field.name, value);
  }, [value]);

  return (
    <MNTextField
      {...field}
      helperText={errorText}
      error={!!errorText}
    ></MNTextField>
  );
};

export default MNUploadFile;

const MNTextField = styled(TextField)(({ theme }) => ({
  width: 301,
  height: 40,
  display: "none",
}));
