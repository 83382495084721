import { alpha } from "@mui/material/styles";

export const sidebarTheme = {
  type: "light",
  palette: {
    primary: {
      main: "#EC008C",
      light: "#FF20A4",
      dark: "#5E3BB7",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FF9800",
      light: "#FFAD33",
      dark: "#DF295E",
      contrastText: "#FFF",
    },
    error: {
      main: "#FF0000",
      light: "#FF3333",
      dark: "#AD001E",
      contrastText: "#FFF",
    },
    warning: {
      main: "#F39711",
      light: "#FFC84C",
      dark: "#BB6900",
      contrastText: "#FFF",
    },
    info: {
      main: "#007AFF",
      light: "#3395FF",
      dark: "#008598",
      contrastText: "#FFF",
    },
    success: {
      main: "#3BD2A2",
      light: "#78FFD3",
      dark: "#00A073",
      contrastText: "#FFF",
    },
    text: {
      primary: "#475259",
      secondary: "#8595A6",
      disabled: "#A2B2C3",
    },
    nav: {
      action: {
        active: "#EC008C",
        hover: "#FF20A4",
      },
      background: {
        active: alpha("#FF20A4", 0.15),
        hover: "#E9ECEF",
      },
      tick: {
        active: "#FF20A4",
        hover: "#ADB5BD",
      },
    },
    divider: "#DEE2E6",
    background: {
      paper: "#FFFFFF",
      default: "#F5F7FA",
    },
    action: {
      active: "#475259",
      hover: "#F5F7FA",
    },
  },
};
