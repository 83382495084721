import React from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { alpha } from "@mui/material/styles";

const JumboIconButton = ({
  children,
  elevation,
  badge,
  width,
  height,
  ...restProps
}) => {
  return (
    <IconButton
      sx={{
        width: width ? width : 40,
        height: height ? height : 40,
        boxShadow: elevation,
        padding: 1.25,
        color: "common.white",
        boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        background:
          "var(--Linear, linear-gradient(300deg, #FF9200 6.64%, #FD840D 9.04%, #F7553A 17.84%, #F2305D 26.25%, #EF1577 34.25%, #ED0586 41.06%, #ED008C 46.66%))",
        "&:hover": {
          background:
            "var(--Linear, linear-gradient(300deg, #FF9200 6.64%, #FD840D 9.04%, #F7553A 17.84%, #F2305D 26.25%, #EF1577 34.25%, #ED0586 41.06%, #ED008C 46.66%))",
        },
      }}
      {...restProps}
    >
      {badge ? (
        <Badge
          variant={badge.variant}
          sx={{
            "& .MuiBadge-badge": {
              top: -5,
              right: -5,
              width: 12,
              height: 12,
              borderRadius: "50%",
              border: 2,
              borderColor: "common.white",
              boxShadow: (theme) =>
                `0 0.5rem 1.25rem ${alpha(theme.palette.primary.main, 0.7)}`,
              backgroundImage: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            },
          }}
        >
          {children}
        </Badge>
      ) : (
        children
      )}
    </IconButton>
  );
};

export default JumboIconButton;
