import JumboIconButton from "@jumbo/components/JumboIconButton";
import { Add, Grain, GridView } from "@mui/icons-material";
import { Grid, Link, Typography } from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import IconBreadcrumbs from "app/pages/components/mui/Breadcrumbs/IconBreadcrumbs";
import React, { Fragment, useEffect, useState } from "react";
import DataForm from "./components/DataForm";
import Table from "./components/Table";
import bannerSlideServices from "app/services/banner-slide-service";

const BannerSlide = () => {
  const [response, setResponse] = useState([]);
  const [editData, setEditData] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [typeActions, setTypeActions] = useState("");

  useEffect(() => {
    getBannerSlide();
    return () => {
      setResponse([]);
    };
  }, []);

  function getBannerSlide() {
    bannerSlideServices
      .getBannerSlide()
      .then((data) => {
        setResponse(data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  return (
    <Fragment>
      <IconBreadcrumbs
        header={
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/app-config/banner-slide"
          >
            <GridView sx={{ mr: 0.5 }} fontSize="inherit" />
            App Config
          </Link>
        }
        page={
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <Grain sx={{ mr: 0.5 }} fontSize="inherit" />
            Banner Slide
          </Typography>
        }
      />
      <PageHeader title={"Banner Slide"} />
      <Grid mb={2} container justifyContent={"end"}>
        <JumboIconButton
          width={40}
          height={40}
          onClick={() => {
            setEditData({});
            setOpenForm(true);
          }}
        >
          <Add />
        </JumboIconButton>
      </Grid>
      <Table
        data={response}
        setOpen={setOpenForm}
        setEditData={setEditData}
        setResponse={setResponse}
        setTypeActions={setTypeActions}
      />
      <DataForm
        data={editData}
        type={typeActions}
        open={openForm}
        setOpen={setOpenForm}
        setResponse={setResponse}
      />
    </Fragment>
  );
};

export default BannerSlide;
