import React, { useEffect, useState } from "react";
import TableReport from "../components/TableReport"; // Import your TableReport component
import IconBreadcrumbs from "app/pages/components/mui/Breadcrumbs/IconBreadcrumbs";
import { Grain } from "@mui/icons-material";
import { Typography } from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { getDisbursementReData } from "app/services/disbursementreport-services";

const Disbursement = () => {
  const [apiData, setApiData] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const page = 1;
    const limit = 1000;

    getDisbursementReData(page, limit)
      .then((data) => {
        const formattedData = data.data.map((item) => {
          const transDate = new Date(item.trans_datetime);

          if (item.status_code === "badRequest") {
            return {
              ...item,
              trans_datetime: "-",
            };
          }
          
          const thaiDate = formatDate(transDate);
          return {
            ...item,
            trans_datetime: thaiDate,
          };
        });

        setApiData(formattedData);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, []);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear() + 543;
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    {
      name: "id",
      label: "ลำดับ",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user_id",
      label: "User_id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "agent_id",
      label: "Agent_id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dst_bank_code",
      label: "รหัสธนาคาร",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dst_bank_account",
      label: "เลขที่บัญชีผู้รับโอน",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "src_bank_acct_name",
      label: "ชื่อบัญชีผู้โอน",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bank_acct_name",
      label: "ชื่อบัญชีผู้รับโอน",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bank_acct_disp_name",
      label: "ชื่อบัญชีผู้รับโอนที่แสดง",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "transfer_ref_no",
      label: "Transfer reference no",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fee",
      label: "ค่าธรรมเนียม",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "จำนวนเงินโอน",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "trans_datetime",
      label: "วันและเวลาวันทำรายการ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status_code",
      label: "status_code",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status_desc",
      label: "status_desc",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const filteredData = apiData.filter((item) => {
    const userMatches =
      searchText === "" ||
      item.user_id.toString().includes(searchText) ||
      item.amount.toString().includes(searchText) ||
      item.dst_bank_account.includes(searchText) ||
      item.trans_datetime.includes(searchText);
    return userMatches;
  });

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    rowsPerPageOptions: [1000, 10], // 1000 is the first option
    rowsPerPage: 1000,
    search: true,
    searchText: searchText,
    onSearchChange: (search) => setSearchText(search || ""),
  };

  return (
    <div>
      <IconBreadcrumbs
        page={
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <Grain sx={{ mr: 0.5 }} fontSize="inherit" />
            ReportDisbursement
          </Typography>
        }
      />
      <PageHeader title={"ReportDisbursement"} />
      <TableReport data={filteredData} columns={columns} options={options} />
    </div>
  );
};

export default Disbursement;
