import React from "react";
import { Box, Breadcrumbs } from "@mui/material";
// import Link from "@mui/material/Link";
// import HomeIcon from "@mui/icons-material/Home";
// import WhatshotIcon from "@mui/icons-material/Whatshot";

const IconBreadcrumbs = ({ header, page }) => {
  // const handleClick = (event) => {
  //   event.preventDefault();
  // };

  return (
    // <JumboDemoCard
    //     title={"Icon Breadcrumbs"}
    //     demoCode={code}
    //     wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
    // >
    //     <div role="presentation" onClick={handleClick}>
    <Box
      sx={{
        width: "fit-content",
        background: "#ffffff",
        padding: "8px 16px",
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        {/* <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          MUI
        </Link>
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          href="/"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Management
        </Link> */}
        {header}
        {page}
      </Breadcrumbs>
    </Box>
    //     </div>
    // </JumboDemoCard>
  );
};

export default IconBreadcrumbs;
