import {
  CurrencyExchangeOutlined,
  InsertChartOutlined,
  GridView,
} from "@mui/icons-material";
import React from "react";

const menus = [
  {
    label: "management",
    type: "section",
    children: [
      {
        label: "App Config",
        type: "collapsible",
        icon: <GridView sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/app-config/banner-slide",
            label: "Banner Slide",
            type: "nav-item",
          },
          {
            // uri: "/",
            label: "Content",
            type: "nav-item",
          },
        ],
      },
      {
        uri: "/disbursement",
        label: "Disbursement",
        type: "nav-item",
        icon: <CurrencyExchangeOutlined sx={{ fontSize: 20 }} />,
      },
      {
        label: "Report",
        type: "collapsible",
        icon: <InsertChartOutlined sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/report/disbursement",
            label: "Disbursement",
            type: "nav-item",
          },
          {
            // uri: "/",
            label: "Repayment",
            type: "nav-item",
          },
        ],
      },
    ],
  },
];

export default menus;
