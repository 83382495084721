import Page from "@jumbo/shared/Page";
import Disbursement from "../pages/report/disbursement/Disbursement"

const redisbursementRoutes = [
    {
        path: "/report/disbursement",
        element: <Page component={Disbursement} />,
    },
];

export default redisbursementRoutes;
