import jwtAuthAxios from "./auth/jwtAuth";

const bannerSlideServices = {};

bannerSlideServices.getBannerSlide = async () => {
  const { data } = await jwtAuthAxios.get("/app/banner-slide");
  return data?.data;
};

bannerSlideServices.postBannerSlide = async (request) => {
  const { data } = await jwtAuthAxios.post("/app/banner-slide", request);
  return data;
};

bannerSlideServices.putBannerSlide = async (request) => {
  const { data } = await jwtAuthAxios.put(
    "/app/banner-slide/" + request.id,
    request
  );
  return data;
};

bannerSlideServices.deleteBannerSlide = async (id) => {
  const { data } = await jwtAuthAxios.delete("/app/banner-slide/" + id);
  return data;
};

export default bannerSlideServices;
