import Page from "@jumbo/shared/Page";
import Disbursement from "../pages/disbursement/Disbursement"

const disbursementRoutes = [
    {
        path: "/disbursement",
        element: <Page component={Disbursement} />,
    },
];

export default disbursementRoutes;
