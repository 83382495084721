import React from "react";
import { Button } from "@mui/material";

const defaultColors = [
  "inherit",
  "primary",
  "secondary",
  "warning",
  "success",
  "info",
  "error",
];
const JumboButton = ({ color, variant, shape, size, sx, ...restProps }) => {
  const [btnSx, setBtnSx] = React.useState(sx);

  React.useEffect(() => {
    if (color && !defaultColors.includes(color)) {
      setBtnSx((sx) => ({
        ...sx,
        color: "common.white",
        bgcolor: color[500],
        "&:hover": {
          bgcolor: color[700],
        },
      }));
    }
  }, [color]);

  React.useEffect(() => {
    if (!sx) {
      setBtnSx(() => ({
        width: "145px",
        height: "41px",
        flexShrink: 0,
        textTransform: "none",
        color: "common.white",
        background:
          "var(--Linear, linear-gradient(300deg, #FF9200 6.64%, #FD840D 9.04%, #F7553A 17.84%, #F2305D 26.25%, #EF1577 34.25%, #ED0586 41.06%, #ED008C 46.66%))",
        "&:hover": {
          background:
            "var(--Linear, linear-gradient(300deg, #FF9200 6.64%, #FD840D 9.04%, #F7553A 17.84%, #F2305D 26.25%, #EF1577 34.25%, #ED0586 41.06%, #ED008C 46.66%))",
        },
      }));
    }
  }, [sx]);

  return <Button variant={variant} sx={btnSx} size={size} {...restProps} />;
};
/* Todo define */
export default JumboButton;
